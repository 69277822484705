@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import "variables.module";

$ring-push-percentage: 16%;

.dashboard-wrapper {
  background: $dashboard-background-color;
  padding: $container-padding;
}

.dashboard-add-wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 48px;
}
.questionnaire-dashboard-wrapper, .general-content {
  &.no-padding {
    padding: 0
  }

  @extend .dashboard-wrapper;

  .chart-container {
    position: relative;
    height: 338px;
    padding: 25px 10px 10px;
    border-radius: 3px;
    border-top: 3px solid #3c8dbc;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background-color: white;
    &.feedback {
      height: 513px;
    }
    &.taller {
      height: 650px;
    }
    &.comments {
      height: auto;
    }
    .compliance-chart {
      margin-top: 100px;
    }
    // When we want a chart to be the same height as an SDV Map
    &.map-height {
      height: 250px;
      min-height: 250px;
    }
    &.auto-height {
      height: auto;
    }
    &.chart-container-fullwidth {
      padding: 0 25px 0;
    }
  }

  .select-button {
    display: inline-block;
    margin-right: 1rem;
    user-select: none;
  }

  .select-buttons-wrapper {
    display: none;
    position: absolute;
    margin-top: -8px;
    margin-bottom: -27px;
    background-color: white;
    z-index: 9999;
    padding-bottom: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    .select-buttons {
      padding-left: 16px;
      padding-right: 16px;

      &.dropdown-content {
        opacity: 1;
        display: block;
        min-height: 28px;
      }
    }

    .dropdown-content {
      top: 5px !important;
      position: relative;
      left: 0 !important;
      box-shadow: none;
    }
  }

  .highcharts-plots-container {
    width: 100%;
    .highcharts-plot-container {
      width: 33%;
      display: inline-block;
    }
    &.with-text {
      margin-top: 100px;
    }
  }

  .highcharts-plot-title-container {
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 100%;

    p {
      color: #c8c8c8;
      font-size: 0.85rem;
      line-height: 1.1rem;
      margin-right: 30px;
      margin-left: 10px;
      text-align: left;
    }

    &.relative-position {
      position: relative;
      p {
        margin-right: 10px;
      }
    }

    .highcharts-plot-title {
      color: #333;
      font-size: 18px;
      font-family: 'Raleway', arial, sans-serif;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .my-highcharts-container {
    position: relative;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

    .outerContainer {
      z-index: 1;
      pointer-events: none;
      width: 100%;
      height: 13px;
      position: absolute;
      top: 140px;
      font-family: 'Open Sans', arial, sans-serif;
      font-size: 10px;
      font-weight: bold;
      opacity: 0;
      color: white;
      text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
    }

    .outerContainer > span {
      float: left;
      width: 50%;
      text-align: center;
      height: 13px;
      line-height: 13px;
      &.leftie {
        margin-right: $ring-push-percentage;
        margin-left: -$ring-push-percentage;
      }
      &.rightie {
        margin-right: -$ring-push-percentage;
        margin-left: $ring-push-percentage;
      }
      & > span {
        display: inline-block;
        width: 100%;
      }
    }
  }
  .feedback-table {
    font-family: 'Source Sans Pro', arial, sans-serif;
    font-size: 14px;
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    th {
      white-space: nowrap;
      user-select: none;
    }
  }
  .feedback-table-container {
    height: 452px;
    overflow-y: scroll;
    margin-top: 1.5rem;
    & > .input-field.inline {
      margin-bottom: 0;
      input {
        margin-bottom: 0;
      }
    }
    &.smaller {
      height: 277px;
    }
    &.with-text {
      margin-top: 50px;
    }
  }
  .dashboard-table {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 30px;
    margin-top: 1.5rem;
    width: 100%;
    z-index: 1;
    thead {
      background-color: #e1e1e1;
    }
    td, th {
      padding: 10px 5px;
    }
    .colored-background {
      padding: 0.2rem 0.5rem 0.25rem;
      font-weight: normal;
      color: white;
      border-radius: 5px;
    }
  }
}

@media (max-width: 992px) {
  .questionnaire-dashboard-wrapper {
    .row > .col:not(:last-child) > .chart-container {
      margin-bottom: 20px;
    }

    &>.col:nth-child(1), &>.col:nth-child(3) {
      flex: 1
    }

    .chart-container {
      height: auto;
      min-height: 338px;
      &.taller {
        height: auto !important;
      }
    }
  }
}

.highcharts-tooltip {
  z-index: 9999 !important;
  color: #333 !important;
  font-family: 'Open Sans', arial, sans-serif !important;
}

.tooltip-header {
  font-size: 11px;
}

.scale-icon {
  @extend .text-muted;
  position: absolute;
  right: 11.25px;
  font-size: initial;
  font-weight: initial;
}

.alert-icon {
  color: $accent-color !important;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.icon-space-right {
  margin-right: 10px;
}

.contains-expand-compress-button {
  padding: 0 0.75rem;
  margin-bottom: 20px;
  position: relative;
}

.questionnaire-chartjs-container {
  background-color: white;
  height: 150px;
  position: relative;

  &.barchart {
    height: 300px;
  }
}

.titlebar {
  display: block;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border: 1px solid black;
  background-color: #f5f5f5;
  padding: 0.25rem 0;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}


// Questionnaire landing page

.questionnaire-card {
  margin-bottom: 1rem;

  .data-overview-card {
    &>.row:first-child {
      position: relative;
    }
    .timeline-icon {
      position: absolute;
      right: 0;
    }
  }
}

.questionnaire-feedback {
  background-image: url('images/feedback.png');
  background-repeat: no-repeat;
  background-position: left top;
}

.questionnaire-questionnaire {
  background-image: url('images/questionnaire.png');
  background-repeat: no-repeat;
  background-position: left;
}

.questionnaire-fill-out {
  background-image: url('images/fill_out_questionnaires.png');
  background-repeat: no-repeat;
  background-position: left;
}

.questionnaire-card {
  .row {
    margin-bottom: 15px;
  }
  ol, ul {
    margin-top: 0;
  }
}

.questionnaire-landing {
  background-color: $dashboard-background-color;

  .row:last-child {
    margin-bottom: 0;
  }
}

.questionnaire-card-with-background-image {
  .row {
    .col.s12 {
      margin-left: 400px;
      width: calc(100% - 400px);
    }
  }
  .row:first-child {
    .col.s12 {
      margin-left: 400px;
      width: calc(100% - 475px);
    }
    &.no-icon {
      .col.s12 {
        width: calc(100% - 400px);
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .questionnaire-dashboard-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .questionnaire-feedback {
    background-image: none;
  }
  .questionnaire-card-with-background-image {
    background-image: none;

    .row {
      .col.s12 {
        margin-left: auto;
        width: 100%;
      }
    }

    .row:first-child {
      .col.s12 {
        margin-left: auto;
        width: calc(100% - 75px);
      }
      &.no-icon {
        .col.s12 {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) {
  .questionnaire-landing {
    .dashboard-card-questionnaire {
      float: right;
    }
  }
}

@media only screen and (min-width: 993px) {
  .questionnaire-landing {
    .dashboard-card, .dashboard-card-enabled {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .chart-container {
    // This allows to center charts vertically on smaller screens
    &.fixed-height {
      height: 338px;
    }
  }
}

div.no-padding-bottom {
  padding-bottom: 0;
}
