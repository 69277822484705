@import "../variables.module";

svg.soccer-field {
  background-image: url("images/soccer_field_horizontal.png");
  background-size: 100% 100%;
  box-sizing: border-box;
  display: inline;
  padding: 0;
  font-family: 'Open Sans', arial, sans-serif;
  width: 100%;
  height: auto;
}

.player-avatar {
  text {
    font-weight: bold;
    font-size: 35px;
  }

  circle {
    stroke: black;
    stroke-width: 3;
  }
}

.soccer-replay-field-container {
  margin-top: 5px;
  text-align: center;

  #soccer-replay-field {
    width: 85%;
    border: 4px solid $secondary-color;
    @extend .z-depth-1;
  }
}

.soccer-content-timeline-container {
  margin-bottom: 0;
}

.soccer-replay-button-div {
  text-align: center;
}

div.heatmap-div {
  width: 100%;
}

canvas.heatmap-canvas {
  width: 100%;
  background-image: url("images/soccer_field_vertical.jpg");
  background-size: 100% 100%;
}

.soccer-dashboard-wrapper {
  background: $dashboard-background-color;
  padding: $container-padding;
}

.soccer-bar-graph {
  margin-top: 10px;
}

.soccer-comparison-table {
  margin-top: 10px;
}

.soccer-general-info {
  .row:first-of-type {
    margin-bottom: 0;
  }
}

.soccer-team-standings {
  text-align: center;
}

.soccer-dashboard-table {
  @extend .striped;
  &#soccer-player-comparison-table {
    @extend .responsive-table, .centered;
  }

  tbody th {
    font-weight: 500;
    color: $muted-color;
  }
}
