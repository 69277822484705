@import "variables.module";

.chartjs-container {
  background-color: white;
  height: 300px;
  position: relative;

  &.barchart {
    height: 300px;
  }
}
.larger-chart {
  .chartjs-container {
    height: 450px;
  }
}

$threex-icon-size: 85px;

.horizontal-bar-icon {
  width: $threex-icon-size !important;
  text-align: center;
}

.contains-horizontal-bar {
  margin-top: 10px;
  height: 33.5px;
  position: relative;
  width: calc(100% - #{$threex-icon-size}) !important;
}

.contains-horizontal-boxplot {
  height: 33.5px;
  position: relative;
  width: calc(100% - #{$threex-icon-size}) !important;
}

.summary-subtitle {
  text-align: center;
  font-weight: 500;
}

.insufficient-data {
  display: inline-block;
  margin-top: 10px;
  margin-left: 17px;
  color: $muted-color;
}

.tooltip-explanation {
  margin-top: 2.5rem;
}

.clock-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.right-side-activity-summary {
  max-width: 572.75px;
}
.half-of-left-side-activity-summary {
  max-width: 294.375px;
}

.clock-ticks canvas {
  background-image: url('images/clock_ticks.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
