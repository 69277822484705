@import "variables.module";

.card-content {
  a:hover, a:focus {
    .text-primary-color {
      color: $secondary-color !important;
    }
  }
}

.buttons-col {
  width: 221px;
}

@media only screen and (max-width: 600px)  {
  .buttons-col {
    width: auto;
  }
}

.card-bottom {
  padding: 0 0.75rem 0.75rem;

  .col:first-child {
    button {
      margin-bottom: 0.75rem;
    }
  }
}

.network-group-container {
  min-height: 100px;
}

.protocols-table, .inactive-protocols-table {
  .state-spacer {
    margin-top: 0;
    margin-bottom: 0;
  }
  .profile-row {
    background-color: transparent;
  }
  .available-member {
    margin-top: 0;
  }
  th {
    @extend .text-muted;
  }
  input[type=text] {
    font-size: inherit;
    text-align: center;
    width: 100px;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
  }
}

@media only screen and (max-width: 992px) {
  .protocols-table, .inactive-protocols-table {
    .state-spacer {
      height: 77px;
    }
    th:nth-last-child(1) {
      margin-top: 62px;
    }
    input[type=text] {
      text-align: left;
      height: auto;
    }
  }
}

.title-icon {
  margin-left: 0.5rem;
}

// Make it so you can't accidentally select a placeholder for a text input
input[type=text], input[type=password], input[type=email], textarea {
  & + label {
    pointer-events: none;
  }
}

.group-button {
  min-width: 120px;
  margin-top: 0.75rem;
  max-width: 150px;
  margin-left: 0.5rem;
}

.connection-button {
  min-width: 40px;
  max-width: 40px;
}

.upgrade-connection {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
}

.delete-connection {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.connection-one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.delete-icon {
  cursor: pointer;
}

.col .row.spaced-down {
  margin-top: 3rem;
}

.nowrap {
  white-space: nowrap;
}

.general-content.group-detail {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -20px;
}

.informed-consent-accept {
  padding: 20px;
  margin-top: -20px;
}

.profile-roles {
  margin-top: 0.75rem;
  min-height: 32px;
}

.dropdown-trigger.member-dropdown {
  position: relative;
  right: 1rem;
}
.dropdown-content.member-roles {
  li {
    font-size: 16px;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
    &.text-accent-color {
      color: $accent-color;
    }
  }
}

// Progress bar

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.group-announcements {
  overflow-y: scroll;
  max-height: 200px;
}

.group-announcement-row {
  border-radius: 20px;
  &:first-child {
    @extend .z-depth-1;
    background-color: navajowhite;
  }
}

.group-announcement-message {
  word-break: break-all;
  margin-left: 0.75rem !important;
}

.group-announcement-remove-margin {
  margin-bottom: 0 !important;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.onboarding-access-toggle {
  span:first-child {
    border-radius: 5px 0 0 5px;
  }

  span:last-child {
    border-radius: 0 5px 5px 0;
  }

  span {
    cursor: pointer;
    display: inline-block;
    border: 1px solid $muted-color;
    padding: 5px 10px;
    color: $muted-color;

    &.active {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}

.input-field > label.longer-label, .input-field > label.longer-label:not(.label-icon).active {
  position: relative;
  transform: none;
  color: $label-color !important;
  left: 0;
  top: 0;
}


// rotating

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  color: $primary-color;
}

.protocol-subscription-active {
  color: grey;
}
.protocol-subscription-completed {
  color: $success-color;
}
.protocol-subscription-canceled {
  color: $error-color;
}

// Override the box shadow because it shows an ugly line on the right
input:not([type]):not(.browser-default):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  box-shadow: 0px 2px 0px -1px $primary-color;
}

.less-padding {
  th, td {
    padding: 7px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.ag-root-wrapper {
  input[type=text]:not(.primary-border-bottom), input[type=number] {
    background-color: revert;
    border: revert;
    outline: revert;
    height: revert;
    margin: revert;
    padding: revert;
    box-shadow: revert;
    box-sizing: revert;
    transition: revert;
  }
}

.avatar-overlapping {
  margin-right: -16px;
  width: 32px;
  height: 32px;
  &.circle.and-more {
    background-color: lightgrey;
    text-align: center;
    padding-top: 5px;
    color: grey;
    font-weight: bold;
    font-size: 1.1rem;
  }
}

.training-session-calendar {
  cursor: pointer;
  min-width: 26px;
  overflow-x: hidden;
  overflow-y: hidden;
  line-height: 1.2em;
  max-height: 2.4em; // 2x line height, which means only shows 2 lines at most

  &:hover {
    color: $primary-color;
  }
}

.calendarmonthview-container {
  display: flex;
  justify-content: center;

  table {
    border-collapse: separate;
  }
}

.calendar-cell {
  background-color: #d8ead5;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
  font-size: 10px;
  &.match {
    background-color: #f7cdcd;
  }
}

@media only screen and (max-width: 600px) {
  .hide-on-phone {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .training-session-calendar {
    white-space: pre;
  }
}
