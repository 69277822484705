@import "variables.module";

.subscription-page-title {
  margin-top: 0;
  color: $secondary-color;
  @extend .text-xxxl;

  &#pick-a-plan-title {
    text-align: center;
  }

  &#full-overview-title {
    text-align: left;
    margin-top: 10vh;
  }
}

.subscription-page-subtitle {
  text-align: center;
}

.contact-us-custom-link {
  color: $accent-color;
  text-decoration: underline;
}

.subscription-banner {
    color: white;
    margin-top: 16px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
}

.subscription-badge {
    margin-top: 16px;
    margin-left: -20px;
}

.subscription-title {
    vertical-align: middle;
    height: 100%;
    line-height: 70px;
}

.premium-profile-container {
  position: relative;
  padding: 0px $large-container-padding 0 $large-container-padding;
  margin-top: -$b;
  margin-bottom: -20px;
  background-clip: content-box;
  .row {
    margin-bottom: 0;
    &.profile-info-row {
      margin-bottom: 20px;
    }
  }
}

.banner-greyed-out {
  background-color: $muted-color;
}

.banner-subscribed {
  background-color: $primary-color;
}

.premium-card-wrapper {
  margin-left: 10px;
  margin-right: 10px;

  &.highlighted {
    @extend .z-depth-4;
    -webkit-transform:scale(1.05) translateZ(50px);
            transform: scale(1.05) translateZ(50px);
  }
}

.premium-card {
  background-color: $background-color;
  position: relative;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: grid;
  padding: 0.75rem 2rem 0.75rem 2rem;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.premium-card-price-number {
  margin-top: 37px;
  margin-bottom: 37px;
  @extend .center-align, .text-huge, .text-secondary-color, .text-bold;
}

.premium-card-content-header {
  @extend .text-heavy, .text-s;
}

.premium-card-content {
  min-height: 40vh;
}

.premium-badge-timeline {
  position: absolute;
  width: 100;
  height: 0;
  border-style: solid;
  border-width: 36px 36px 0 0;
  border-color: $accent-color transparent transparent transparent;
  z-index: 999;

  &.super-premium {
    border-color: $orange-color transparent transparent transparent;
  }
}

.premium-badge-timeline-insets {
  margin-left: -36px;
  margin-top: -20px;
}

.premium-badge-timeline-icon {
  color: white;
  margin-left: 3px;
  margin-top: -33px;
  position: absolute;

  &.super-premium {
    margin-left: 1px;
    margin-top: -35px;
  }
}

.premium-overlay {
  .wrapper {
    width: 100%;
    position: relative;

    >.blur-overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9; // Note that this needs to be lower than the menu, as it might overlap
      &.active {
        background: rgba(255,255,255,0.8);
        pointer-events: none;
      }
      &.inactive {
        background: rgba(255,255,255,0.8);
        pointer-events: all;
      }
    }
  }
}

.premium-button {
  text-align: center;
  min-height: 40px;
  line-height: 1.15;

  &.super-premium {
    background-color: $orange-color;
    border-color: $orange-color !important;
  }
}

.premium-card-list {
  tr {
    border: none;
    @extend .text-m;
  }
}

.premium-button-insets {
  margin: 30px;
}

.premium-question-insets {
  margin: 16px;
}

.horizontal-divider {
  margin: 15px 0 15px 0;
  height: 0;
  border: solid 1px $secondary-color;
}

.premium-features-table {
  tr {
    &.header-row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:not(.header-row) {
      border: none;
    }
  }

  td {
    height: 8vh;
  }

  .category-table-header {
    @extend .text-xl;
  }

  .subscription-table-header {
    text-align: center;
    width: 20%;
    @extend .text-l;
  }

  .feature-description {
    @extend .text-l;
  }

  .feature-availability-entry {
    font-size: 22px;
    text-align: center;
    @extend .text-l;
  }

  td, th {
    &:nth-child(3) {
      background-color: $accent-color-light;
    }
  }
}

.collapsible-menu {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;
}

.collapsible-menu:hover {
  background-color: #eee;
}

.collapsible-content {
  padding: 18px 18px;
  display: none;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
  background-color: #F8F8F8;
}

.active {
  display: block;
  overflow: default;
}

.premium-table {
  padding: 18px;
}
