.soccer-details-desktop {
  @extend .hide-on-med-and-down;
}

.soccer-details-mobile {
  @extend .hide-on-large-only;
}

.soccer-team-name-div {
  align-content: center;
  text-align: center;
}

.soccer-details-score-div {
  text-align: center;
  @extend .flow-text;

  h5 {
    margin: 0;
  }
}

#soccer-field-timeline {
  border: 4px solid $secondary-color;
}

.soccer-field-timeline-div {
  text-align: center;

  .soccer-field {
    max-width: 100%;
  }
}
