@import "variables.module";

.timeline-card {
  padding: 20px 36px 20px;
  background-color: $background-color;
  margin-bottom: 20px;
  &.container {
    margin-top: 0
  }
}

.timeline-card-compact {
  @extend .timeline-card;
  padding: 0;
}

.timeline-card-header {
  display: flex;
  a {
    display: block;
  }
}

.timeline-title {
  margin-bottom: 19px;
}

.timeline-profile-picture {
  display: block;
  width: 55px !important;
  height: 55px !important;
  min-width: 55px !important; // Because of object-fit: contain, the width argument is sometimes ignored, but this is not.
  min-height: 55px !important;
  object-fit: contain;
}

.timeline-profile-details {
  line-height: 16px;
  margin-top: 2.5px;
  margin-bottom: 0;
  margin-left: 21px;
  &.data-card-title {
    line-height: 25px;
    margin-right: 1rem;
  }
}

.timeline-icon {
  margin-top: -20px;
  margin-left: auto;
  background-color: $primary-color;
  color: $background-color;
  height: 75px;
  width: 75px;
  min-width: 75px;
  font-size: 40px;
  line-height: 75px;
}

.timeline-card-content {
  margin-left: 76px;
  margin-top: 19px;
}

.timeline-card-content-compact {
  padding: 0;
  margin-left: 0px;
  margin-top: 19px;
}

.timeline-compact-wrapper {
  display: inline-flex;
  width: 100%;
  padding-bottom: 16px;
  align-content: flex-start;
}

.timeline-icon-compact {
  margin-right: 16px;
  background-color: $primary-color;
  color: $background-color;
  height: 75px;
  width: 75px;
  min-width: 75px;
  font-size: 40px;
  line-height: 75px;
}

.from-group {
  margin-bottom: 0;
}

.timeline-compact-title-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .timeline-cards {
    .timeline-card > .timeline-card-header {
      display: block;
      position: relative;

      & > a {
        display: block;
        width: calc(100% - 80px);
      }

      .timeline-card-header > div:last-child {
        vertical-align: top;
      }
    }

    .timeline-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    .timeline-card {
      min-width: 240px;
    }

    .timeline-card.container > .flexbox {
      display: block;
      position: relative;
    }
  }
}

@media only screen and (max-width: 800px) {
  .data-cards {
    .timeline-card-header {
      display: block;
      position: relative;
      &>div:first-child {
        width: calc(100% - 80px);
      }
    }

    .timeline-icon {
      position: absolute;
      top: -2px;
      right: 0;
    }

    .timeline-card.container > .flexbox {
      display: block;
      position: relative;
    }
    .data-buttons-wrapper {
      margin-top: 2rem;
      margin-left: 21px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .timeline-card-content {
    margin-left: 0;
  }
}

.button-row {
  position: relative;
  &>a {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

.timeline-card-details {
  margin-top: 33px;
}

.sport-table {
  tr {
    border-bottom: none;
    td {
      padding: 1px 5px;
    }
  }
}

.sport-fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sport-field {
  margin-bottom: 5px;
  margin-right: 0.5rem;
  overflow-x: hidden;
}

.sport-field-compact {
  margin-bottom: 0px;
  margin-right: 0.5rem;
  overflow-x: hidden;

  @media only screen and (min-width: 1120px) {
    margin-top: 12px;
  }
}

.timeline-card-visualization {
  min-height: 250px;
}

.timeline-group-name-wrapper {
  float: right;
}

/* icon is 15x15 px but for some reason the icon containers height is 22.5 px (but still 15px wide) */
/* this makes positioning it exactly on the corner a pain, so we use a fixed offset instead */
.absolute-top-left-icon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 999;
}

.relative {
  position: relative;
}

.accent-color {
  color: $accent-color
}

@media only screen and (max-width: 900px) {
  .sport-fields {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 5px;
  }
  .sport-field {
    margin-bottom: 0;
    &>div {

      display: inline-block;

      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  .timeline-group-name-wrapper {
    display: block;
    position: relative;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1120px) {

  .sport-fields {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 5px;
  }

  .sport-field-compact {
    margin-bottom: 0;
    &>div {

      display: inline-block;

      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}

.timeline-group-name-wrapper p:nth-child(2) {
  margin-left: 1rem;
}

.rpe {
  border-bottom-style: solid;
  border-bottom-width: 2px;

  &.rpe-0 {
    border-bottom-color: #91cbf1;
  }

  &.rpe-1 {
    border-bottom-color: #42c4f5;
  }

  &.rpe-2 {
    border-bottom-color: #93d4d0;
  }

  &.rpe-3 {
    border-bottom-color: #00b149;
  }

  &.rpe-4 {
    border-bottom-color: #00b149;
  }

  &.rpe-5 {
    border-bottom-color: #fde82b;
  }

  &.rpe-6 {
    border-bottom-color: #fde82b;
  }

  &.rpe-7 {
    border-bottom-color: #f0a42c;
  }

  &.rpe-8 {
    border-bottom-color: #f0a42c;
  }

  &.rpe-9 {
    border-bottom-color: #eb572f;
  }

  &.rpe-10 {
    border-bottom-color: #a13227;
  }
}

.highcharts-red {
  border-bottom: 2px solid #BE1111;
}
.highcharts-orange {
  border-bottom: 2px solid #FA8832;
}
.highcharts-green {
  border-bottom: 2px solid #0A720A;
}

.code {
  padding: 2rem;
  border-radius: 5%;
  background-color: ghostwhite;
  .null { color: darkgray }
  .boolean { color: #eb572f }
  .key { color: indianred }
  .string { color: #00b149 }
  .number { color: cornflowerblue }
}
