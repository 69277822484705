@import "variables.module";

.stats-card {
  @extend .z-depth-1;
  text-align: center;

  .stats-card-header {
    background-color: $secondary-color;
    color: white;
    border-bottom: 2px solid $black-color;
  }

  .stats-card-body {
    p {
      font-size: xx-large;
    }
  }

  .stats-card-footer {
    color: white;
    background-color: $primary-color;
    border-top: 2px solid $black-color;
  }
}
