.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 10px 0;
  user-select: none;

  .page-link {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin: 5px;
    text-align: center;
    line-height: 30px;

    &.active {
      background-color: $primary-color;
      color: $background-color;
    }
  }

  .page-dots {
    width: 30px;
    height: 30px;
    margin: 5px;
    text-align: center;
    line-height: 30px;
    color: $muted-color;
  }

  .iterate-link {
    cursor: pointer;
    line-height: 40px; // height of parent is 30px + 10px margin
    margin: 0px 20px;

    &.disabled {
      cursor: text;
      color: $muted-color;
    }
  }
}
