@import "variables.module";

.skating-dashboard-wrapper {
  background: $dashboard-background-color;
  padding: $container-padding;
}

.hr-zones {
  display: flex;
  flex-flow: row;
}

.hr-zones p label span {
  padding-left: 25px !important;
}

.hr-zones label {
  margin-right: 15px;
}

.zone-radios {
  display: flex;
  justify-content: center;
}

.value-outline {
  border-color: #39acdd;
  border-radius: 0 0 10px 10px;
  border-width: 2px;
  border-style: solid;
  padding: 8px;
  border-top-width: 6px;
  max-width: 300px;
}

.range-title {
  display: block;
  color: $label-color;
  font-size: 0.8rem;
  margin-bottom: 10px;
  margin-top: 5px;
  &.larger {
    font-size: 1rem;
  }
}

.graph-padding {
  padding: 0 1.5rem;
}
