.markdown-wrapper {
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }

  pre {
    background-color: $gray-chartjs;
    border-radius: 5px;
    padding: 8px;
    overflow: scroll;
  }

  code {
    background: $gray-chartjs;
    border-radius: 3px;
    padding: 1px;
  }

  ul {
    padding-left: 20px;
    li {
      list-style-type: disc;
    }
  }
  .logo-wrapper {
    text-align: center;
  }
}
