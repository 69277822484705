/* common */
/* see https://codepen.io/nxworld/pen/oLdoWb */
.box {
  position: relative;
  min-height: 150px;
}

.ribbon {
  opacity: 0.9;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: $primary-color;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 18px/1 sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
}

.ribbon-top-right {
  top: 0;
  right: 0;

  span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
}
