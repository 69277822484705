@use "sass:math";

@import "variables.module";

.bell-notification {
  position: relative;
  display: inline-block;
  .bell-badge {
    position: absolute;
    min-width: 0;
    top: 25px;
    right: -5px;
    background: $accent-color;
    padding:5px;
    box-sizing: border-box;
    border-radius: 100%;
  }
}

.contains-button-badge {
  position: relative;
}

.button-badge {
  position: absolute;
  min-width: 0;
  top: 15px;
  right: 15px;
  background: $accent-color;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 100%;
  z-index: 100;
  pointer-events: none;
}

.data-overview-card {
  .contains-button-badge {
    width: 200px;
    &.contains-wider-button {
      width: 275px;
    }

    .button-badge {
      top: 4px;
      right: 5px;
    }
  }
}

.smaller-font {
  font-size: 0.8rem;
}

a {
  color: $primary-color;
}

a:hover, a:focus {
  color: $secondary-color;
}

.disabled-link {
  pointer-events: none;
}

.disabled-button {
  pointer-events: none;
  filter: grayscale(100%);
}
button.disabled, button[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
  border-color: #DFDFDF !important;
}

.static-page-wrapper {
  background: #ecf0f6;
  padding: $container-padding;
}

.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.notfound-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.fallback-component-wrapper {
  width: 100%;
  height: 100%;
  color: #666;
  text-align: center;
  font-family: arial, sans-serif;
}

.fallback-component {
  width:560px;
  height:200px;
  position:absolute;
  left:50%;
  top:50%;
  margin:-100px 0 0 -280px;
  background-color: #ffffff;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.fallback-component-inner {
  width: auto;
  padding: 10px;
  margin: 0 auto;
}

.text-huge {
  font-size: $huge;
}

.text-xxxl {
  font-size: $xxxl;
}

.text-xxl {
  font-size: $xxl;
}

.text-xl {
  font-size: $xl;
}
.questionnaire-dashboard-wrapper, .questionnaire-landing {
  .dashboard-card-title.text-xl {
    font-size: $l;
  }
}

.text-l {
  font-size: $l;
}

.text-m {
  font-size: $m;
}

.text-s {
  font-size: $s;
}

.text-heavy {
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

.text-light {
  font-weight: 300;
}

.text-medium {
  font-weight: 400;
}

.align-center {
  text-align: center;
}

.text-primary-color {
  color: $primary-color;
}

.text-secondary-color {
  color: $secondary-color;
}

.text-accent-color {
  color: $accent-color;
}

.text-background-color {
  color: $background-color;
}

.text-inactive-color {
  color: $inactive-color;
}

.text-black {
  color: $black-color;
}

.text-right {
  text-align: right;
}

h1 {
  @extend .text-m;
  @extend .text-heavy;
  @extend .text-primary-color
}

h2 {
  @extend .text-s;
  @extend .text-heavy;
  @extend .text-primary-color
}

.background-primary {
  background-color: $primary-color;
}

.background-secondary {
  background-color: $secondary-color;
}

.background-accent {
  background-color: $accent-color;
}

.background-muted {
  background-color: $muted-color;
}

.background-background {
  background-color: $background-color;
}

.background-background:focus {
  background-color: $background-color;
}

.decoration-primary, .pointer.decoration-primary {
  &:hover, &:focus {
    color: $primary-color !important;
  }
}

.decoration-background, .pointer.decoration-background {
  &:hover, &:focus {
    color: $background-color !important;
  }
}

.btn {
  background-color: $button-primary-color;
}

.no-jagged-lines {
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.01);
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateZ(2deg);
}


$a: 4; // Coefficient of the radius w.r.t parent width
$alpha: acos(math.div(1, 2*$a)); // Angle between radius and circle chord
$b: $a * 100% * (1 - sin($alpha)); // How much the circle segment sticks out of the parent component
$rounded-width: 2 * 100% * $a;
.bottom-rounded,
.top-rounded {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-clip: content-box; // No background on padding (rounded segment)
}


.bottom-rounded {
  padding-bottom: calc(#{$b} + 20px) !important;
}

.top-rounded {
  padding-top: calc(#{$b} + 20px) !important;
}

.app {
  min-width: 320px; // iphone 5
  position: relative;

  .__react_component_tooltip.place-top {
    margin-top: 0;
    border: none;
  }
}

.app-wrapper {
  min-height: 100vh;
  height: 100%;
  display: flex; // set the footer at the bottom of the page
  flex-direction: column;
  &.logout {
    position: relative;
    min-height: 100%;
  }
}


.top-rounded::before,
.bottom-rounded::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  width: $rounded-width;
  border-radius: $rounded-width;
  background-color: inherit;
  @extend .no-jagged-lines;
}

.top-rounded::before {
  padding-top: $rounded-width;
  right: (-100% * $a) + 50%;
  top: 0;
}

.bottom-rounded::after {
  padding-top: $rounded-width;
  right: (-100% * $a) + 50%;
  bottom: 0;
}

.button-primary,
.button-secondary,
.button-muted,
.button-accent,
.button-background {
  border: 1px solid;
  border-radius: 0;
  padding: 0.75rem;
  width: 100%;
}

.button-primary {
  border-color: $primary-color;
}

.button-autowidth {
  width: auto;
  margin-right: 1.5rem;
}

.button-secondary {
  border-color: $secondary-color;
}

.button-accent {
  border-color: $accent-color !important;
}

.button-muted {
  border-color: $muted-color;
}

.button-background {
  border-color: $background-color;
}

.button-primary:focus:not(.grayed-out) {
  background-color: $primary-color;
  color: $background-color;
}

.button-primary:hover:not(.grayed-out) {
  background-color: rgba($primary-color, 0.8);
  color: $background-color;
}

.button-secondary:focus {
  background-color: $secondary-color;
  color: $background-color;
}

.button-secondary:hover {
  background-color: rgba($secondary-color, 0.8);
  color: $background-color;
}

.button-accent:focus {
  background-color: $accent-color;
  color: $background-color;
}

.button-accent:hover {
  background-color: rgba($accent-color, 0.8);
  color: $background-color;
}

.button-background:focus {
  background-color: $background-color;
  color: $background-color;
}

.button-background:hover {
  background-color: rgba($background-color, 0.8);
}

.button-dropdown {
  position: relative;
}

.button-icon {
  margin-right: 0.34rem;
}

.bigger-icon {
  color: $muted-color;
  font-size: 50px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  /*height: 40px;*/
  padding-left: 10px;
  z-index: 9999;
  display: none;
}

.dropdown-deployed {
  display: block;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: aliceblue;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

@media only screen and (max-width: 992px) {
  .no-margin-bottom-inside {
    & > .row {
      margin-bottom: 0;
    }
  }
}

.extra-bottom-margin {
  margin-bottom: 16.5px;
}

.sports-icon {
  height: 75px;
  width: 75px;
  min-width: 75px;
  color: $muted-color;
  background-color: $background-color;
  font-size: 40px;
  line-height: 75px;
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  border-bottom: 1px solid $primary-color;
}
.select-multiple-icon > .pointer {
  .sports-icon {
    color: $primary-color;
    border-bottom-color: $primary-color;
  }
  &.grayed-out {
    .sports-icon {
      border-bottom-color: transparent;
    }
  }
}

label {
  &.active {
    color: $primary-color !important;
  }

  &.colored {
    color: $primary-color;
  }

  & + svg {
    color: $primary-color;
  }
}

.toggle {
  svg {
    color: $primary-color;
  }
}

.dropdown-content {
  span:hover label, span:focus label {
    color: $secondary-color !important;
  }
}

.profile-submit-button {
  margin-top: 3rem;
  position: absolute;
  bottom: -80px;
  width: 250px;
}
.profile-delete-message {
  margin-top: 100px;
}

.profile-container {
  padding: $container-padding $large-container-padding;

  input:disabled, textarea:disabled {
    color: inherit !important;
    border-bottom: none !important;
    -webkit-text-fill-color: inherit !important; /* required for safari */
    opacity: 1; /* required for ios */

    & + label {
      color: $muted-color !important;
    }
  }

  textarea:disabled {
    white-space: pre;
  }

  label[for="sports-list"] {
    color: $muted-color !important;
  }
  .sports-list {
    padding-top: .8rem;
    .sports-icon {
      border-bottom: none !important
    }
  }
}

.row .col.group-container {
  padding: $container-padding $large-container-padding;
}

.dropzone {

  border-color: $primary-color;

  &.dropzone-active {
    border-color: $secondary-color;
  }

  .drag-message {
    text-align: center!important;
  }

  min-width: 200px;
  min-height: 200px;
  padding: 10px;
  border-style: dashed;
  cursor: pointer;
}

.faded-out {
  opacity: 0.3;
}

.fade-in-on-hover:hover{
  opacity: 1;
}

.color-on-hover:hover {
  filter: grayscale(0);
}

.data-sources-container {
  padding-top: 4vh;
  padding-bottom: 4vh;
  background-color: $border-color;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@mixin show-strava-button($show) {
  @if $show {
    width: 190px;
    height: 48px;
    display: inline-block;
    margin-left: -16px;
    margin-top: 5px;
  } @else {
    display: none;
  }
}

.integration-icon {
  width: 200px;
  height: 70px;
  object-fit: contain;
  padding: 5px 20px;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  .show-only-on-hover {
    @include show-strava-button(true)
  }
  .hide-on-hover {
    @include show-strava-button(false)
  }
  &:hover {
    .show-only-on-hover {
      @include show-strava-button(false)
    }
    .hide-on-hover {
      @include show-strava-button(true)
    }
  }
}

.remove-integration {
  visibility: hidden;
  position: absolute;
  right: -1px;
  color: $primary-color;
  font-size: 20px;
  cursor: pointer;
  background-color: $background-color;
  border-radius: 50%;
  text-align: center;
  height: 23px;
  width: 23px;
  line-height: 23px;
}

.remove-integration:hover {
  visibility: visible;
}

.active-integration {
  position: relative;
  display: inline flow-root;
  &:hover {
    .remove-integration {
      visibility: visible;
    }
  }
}

.native-app-store-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
  margin-bottom: 20px;
  cursor: pointer;
}

.store-badge,
.store-badge-google {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-badge {
  height: 40px;
}

.store-badge-google {
  height: 57px;
  margin: -8px;
}

.store-badge img,
.store-badge-google img {
  height: 100%;
  object-fit: contain;
}

.y-scrollable {
  overflow-y: scroll;
}

.x-scrollable {
  overflow-x: scroll;
  font-family: monospace;
}

.spin-wrapper {
  position: relative;
  width: 100%;
  height: 10rem;
  background: #ffffff;

  .spinner {
    position: absolute;
    height: 60px;
    width: 60px;
    border: 3px solid transparent;
    border-top-color: #001560;
    top: 50%;
    left: 50%;
    margin: -30px;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &:before, &:after {
      content:'';
      position: absolute;
      border: 3px solid transparent;
      border-radius: 50%;
    }

    &:before {
      border-top-color: #DC4C14;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      animation: spin 3s linear infinite;
    }

    &:after {
      border-top-color: #37B5DB;
      top: 6px;
      left: 6px;
      right: 6px;
      bottom: 6px;
      animation: spin 4s linear infinite;
    }
  }
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.back-button {
  cursor: pointer;
  color: $secondary-color;
  display: block;
  width: 15rem;
  .button-icon {
    -webkit-transition: opacity 0.15s ease, -webkit-transform 0.15s ease;
    transition: opacity 0.15s ease, transform 0.15s ease, -webkit-transform 0.15s ease;
    opacity: 0.66;
  }
  &:hover, &:focus {
    color: $primary-color;
    .button-icon {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
      opacity: 1;
    }
  }
}

.slightly-rounded {
  border-radius: 5px
}

.login-warning {
  @extend .slightly-rounded;
  @extend .warning;
  color: #ffffff;
  padding: 1rem!important;
}

.tabs {
  overflow-x: hidden; // prevents horizontal scrollbar overlaid on tabs
  .tab {
    text-transform: none;
    background-color: transparent;
  }
}
.small-absolute-tabs {
  &>.tabs {
    position: absolute;
    width: 100px;
    border-radius: 20px;
    z-index: 1000;
    height: 26px;
    top: 0;
    .tab {
      height: 24px;
      line-height: 24px;
    }
  }
  &>.tab-content {
    margin-top: 0;
  }
}

.tab-content {
  margin-top: 1rem;
  display: none;
  &.active {
    display: block;
  }
}

.chips {
  padding-top: 0.25rem;
}

// Make the checkmark in multiple select boxes true
[type="checkbox"]:checked + span:not(.lever):before {
  border-right-color: $primary-color;
  border-bottom-color: $primary-color;
}

.multiselect-edit-button {
  color: $primary-color;
  border: 1px solid $primary-color;
  background-color: white;
  font-size: 1.5rem;
}

span.badge {
  color: initial;
}

.button-disabled {
  background-color: $inactive-color;
  border-color: $inactive-color;
  color: $background-color;
  cursor: default;
}

.button-disabled:hover {
  background-color: $inactive-color !important;
  border-color: $inactive-color !important;
  color: $background-color !important;
}

.no-filter {
  filter: none;
}

.pin-to-bottom {
  position: absolute;
  bottom: 0;
}

// Ag-Grid Table

.ag-theme-alpine.larger-table-header {
  .ag-checkbox-input[type="checkbox"]:not(:checked), .ag-checkbox-input[type="checkbox"]:checked {
    pointer-events: initial;
  }
  .ag-header-cell-text {
    text-overflow: initial;
    white-space: initial;
    overflow: initial;
  }
  .ag-cell-wrapper, .ag-popup {
    .ag-popup-editor {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 330px;
      top: 84px !important;
      left: 20px !important;
    }
    textarea {
      width: 300px;
      height: 150px;
    }
    .search-wrapper {
      padding: 10px;
    }
    .padding-wrapper {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      & > .row {
        margin-bottom: 0;
      }
    }
    .input-field {
      width: 300px;
      .select-wrapper+label {
        top: -36px;
        left: 16px;
      }
      .select-wrapper {
        svg.caret {
          z-index: 500;
          margin-right: 26px;
        }
        .select-dropdown {
          width: 84%;
          border: none;
          margin-top: 32px;
          padding-left: 10px;
          margin-left: 16px;
          &:focus-visible {
            outline-color: $primary-color;
          }
        }
      }
    }
    .react-datepicker-wrapper {
      input {
        width: 96%
      }
    }
    .react-datepicker-popper {
      width: 313px;
    }
  }
  .ag-cell-not-inline-editing:hover {
    cursor: pointer;

    &::before {
      z-index: -1;
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .4;
      background-image: url("images/pen-to-square-solid.svg");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 14px;
    }
  }
  .ag-cell-inline-editing {
    .ag-cell-edit-wrapper {

      input.ag-input-field-input {
        height: 37px;
        &:focus {
          height: 41px;
        }
      }
    }
  }
  .ag-cell-wrap-text {
    word-break: break-word;
  }
}

.read-only {
  .ag-theme-alpine.larger-table-header {
    .ag-cell-not-inline-editing:hover {
      &::before {
        background: none;
      }
    }
  }
}
