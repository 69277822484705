@import "variables.module";

.stats-list .stats-table-div {
  margin-top: 10px;
  table.hovering-stats-table {
    @extend .centered;
    @extend .striped;
    border: 2px solid $black-color;

    tbody th {
      font-weight: 500;
      color: $muted-color;
    }
  }
}
