@import "variables.module";

.map-view {
  border: 2px solid $secondary-color;
  border-radius: 2px;
  @extend .z-depth-2;
}

.charts-view {
  margin-top: 50px;
  //border: 2px solid $secondary-color;
  //border-radius: 2px;
  //@extend .z-depth-2;
}

.stats-table .table {
  @extend .striped;
}

.spreadsheet-container {
  overflow: scroll;
  height: 500px;
  width: 100%;
}
