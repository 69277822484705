@use "sass:math";

@import "variables.module";

@keyframes BOUNCING {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
}

@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
      opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
      opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
      opacity: .2;
    }
}

.saving {
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: .2s;
  }

  span:nth-child(3) {
    animation-delay: .4s;
  }
}


.breathing {
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal;
}
$zeropct: 0.9;
$twentyfivepct: 1;
$seventypct: 0.9;
$hundredpct: 0.9;

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale($zeropct);
    transform: scale($zeropct);
  }

  25% {
    -webkit-transform: scale($twentyfivepct);
    transform: scale($twentyfivepct);
  }

  70% {
    -webkit-transform: scale($seventypct);
    transform: scale($seventypct);
  }

  100% {
    -webkit-transform: scale($hundredpct);
    transform: scale($hundredpct);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale($zeropct);
    -ms-transform: scale($zeropct);
    transform: scale($zeropct);
  }

  25% {
    -webkit-transform: scale($twentyfivepct);
    -ms-transform: scale($twentyfivepct);
    transform: scale($twentyfivepct);
  }

  70% {
    -webkit-transform: scale($seventypct);
    -ms-transform: scale($seventypct);
    transform: scale($seventypct);
  }

  100% {
    -webkit-transform: scale($hundredpct);
    -ms-transform: scale($hundredpct);
    transform: scale($hundredpct);
  }
}


.ringing {
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

$ring-scaling-factor: 5;
@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(math.div(30deg, $ring-scaling-factor)); }
  3% { transform: rotate(math.div(-28deg, $ring-scaling-factor)); }
  5% { transform: rotate(math.div(34deg, $ring-scaling-factor)); }
  7% { transform: rotate(math.div(-32deg, $ring-scaling-factor)); }
  9% { transform: rotate(math.div(30deg, $ring-scaling-factor)); }
  11% { transform: rotate(math.div(-28deg, $ring-scaling-factor)); }
  13% { transform: rotate(math.div(26deg, $ring-scaling-factor)); }
  15% { transform: rotate(math.div(-24deg, $ring-scaling-factor)); }
  17% { transform: rotate(math.div(22deg, $ring-scaling-factor)); }
  19% { transform: rotate(math.div(-20deg, $ring-scaling-factor)); }
  21% { transform: rotate(math.div(18deg, $ring-scaling-factor)); }
  23% { transform: rotate(math.div(-16deg, $ring-scaling-factor)); }
  25% { transform: rotate(math.div(14deg, $ring-scaling-factor)); }
  27% { transform: rotate(math.div(-12deg, $ring-scaling-factor)); }
  29% { transform: rotate(math.div(10deg, $ring-scaling-factor)); }
  31% { transform: rotate(math.div(-8deg, $ring-scaling-factor)); }
  33% { transform: rotate(math.div(6deg, $ring-scaling-factor)); }
  35% { transform: rotate(math.div(-4deg, $ring-scaling-factor)); }
  37% { transform: rotate(math.div(2deg, $ring-scaling-factor)); }
  39% { transform: rotate(math.div(-1deg, $ring-scaling-factor)); }
  41% { transform: rotate(math.div(1deg, $ring-scaling-factor)); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

.grow {
  transition: all .2s ease-in-out;
  &:hover {
    -webkit-transform:scale(1.05) translateZ(50px);
            transform: scale(1.05) translateZ(50px);
    -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.05);
  }
}
