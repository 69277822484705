@import "variables.module";

.notifications-wrapper {
  position: absolute;
  width: 100%;
  top: 100%;
  padding-right: 0.75rem;
  cursor: default;
}

.notifications-wrapper-mobile {
  left: 0
}

.nav-buttons-wrapper .notifications-nav-button:hover {
  color: $primary-color;
}

.bell-notification:hover {
  color: $secondary-color;
}

.notifications {
  position: absolute;
  right: 0;
  width: 400px;
}

.mobile {
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.notifications-list {
  line-height: 1rem;
  text-align: left;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  background-color: $background-color;
}

.notification-row {
  border-top: none;
  margin-bottom: 0 !important;
  padding: 0.75rem;
  &:not(:first-child) {
    border-top: 1px solid $border-color;
  }
}

.notifications-title {
  margin-bottom: 0 !important;
}

.notification-timestamp {
  color: #6C757D;
  margin-bottom: 0 !important;
  margin-top: 5px !important;
}

.responsive-link:hover {
  color: $secondary-color;
}

.icon-active {
  color: $secondary-color;
}

.notification-type {
  color: #bbb;
}

.notification-view {
  color: $primary-color;
}

.notification-view:hover {
  color: $secondary-color;
}

.nav-bar {
  background-color: $background-color;
  height: 80px;
  .container {
    margin-top: 0;
  }
}

.sdv-branding {
  display: flex;
  &.col.l5 {
    width: 310px;
    padding-right: 0;
  }
}

.top-menu-pages {
  width: calc(100% - 490px) !important;
}

@media (max-width: 992px) {
  .sdv-branding {
    justify-content: center;
  }
}

.sdv-title {
  color: $secondary-color;
  height: 80px;
  line-height: 72px;
  font-size: $xl;
  margin: 0;
  padding-left: 18px;
}

.sdv-logo {
  padding-top: 10px;
  height: 95px;
}

.nav-item {
  color: $primary-color;
  height: 80px;
  font-size: $m;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
  padding: 0 !important;
}

.nav-item.selected, .nav-item:hover, .nav-item:focus {
  color: $secondary-color;
}

.nav-buttons-wrapper {
  display: flex;
  position: relative;
  flex-flow: row-reverse;
  padding: 0 !important;
  &.col.l2 {
    float: right;
    width: 180px;
  }
}

.nav-button {
  color: $primary-color;
  font-size: 20px;
  line-height: 80px;
  text-align: center;
  margin: 0 8px;
  cursor: pointer;
}

.nav-button-img {
  max-height: 30px;
}

.nav-button.selected, .nav-button:hover, .nav-button:focus {
  color: $secondary-color;
}

.hamburger {
  margin-top: 28px;
  margin-left: auto;
  display: block;
  height: 24px;
  width: fit-content;
  position: relative;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;

  background: $primary-color;
  border-radius: 3px;

  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.hamburger {
  span:first-child {
    transform-origin: top left;
  }

  span:last-child {
    transform-origin: bottom left;
  }
}

.hamburger-badge {
  position: absolute;
  min-width: 0;
  top: -5px;
  right: -5px;
  background: $accent-color;
  padding:5px;
  box-sizing: border-box;
  border-radius: 100%;
}

.hamburger.deployed {
  span:first-child {
    opacity: 1;
    transform: rotate(45deg);
  }
  span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  span:last-child {
    opacity: 1;
    transform: rotate(-45deg);
  }
}

.vertical-nav {
  position: absolute !important;
  top: -140px;
  width: 100%;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.vertical-nav.deployed {
  transform: translateY(220px);
  border-bottom: 1px solid $border-color;
}

.vertical-nav {
  .nav-item {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: $background-color;
  }

  .nav-item:first-child {
    height: 60px;
    padding-top: 20px !important;
  }

  .nav-button {
    line-height: 40px;
  }

  // nav buttons are in reversed order
  .nav-button:last-child {
    margin-left: auto;
  }
  .nav-button:first-child {
    margin-right: auto;
  }
}
