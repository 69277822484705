.create-snapshot {
  .select-wrapper > ul {
    min-height: 300px;
  }
}

.snapshot-container {
  padding: 11px;
  position: relative;
  &>.valign-wrapper {
    &>.col:nth-child(1), &>.col:nth-child(3) {
      flex: 1
    }
    &>.col:nth-child(2) {
      margin-left: 75px;
      min-width: 0;
      .one-line {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .timeline-icon {
    margin-top: 0;
    position: absolute;
    top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .snapshot-container {
    & > .valign-wrapper {
      & > .col:nth-child(2) {
        margin-left: auto;
      }
    }
    .timeline-icon {
      margin-top: -11px;
      margin-bottom: -64px;
      position: static;
    }
  }
}

.snapshot-scroll {
  overflow-y: scroll;
  max-height: 500px;
  width: 100%;
  overflow-x: hidden;
}
