@charset "UTF-8";

// Internet Explorer stuff
#noscript {
  display: none;
}
.lt-ie10 {
  #noscript {
    display: block;
  }
  .container {
    display: none !important;
  }
}


// Materialize icons
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Colors
@import "../../../node_modules/materialize-css/sass/components/_color-variables.scss";

$input-border-color: color("grey", "darken-2");
$tabs-bg-color: rgba(57, 172, 221, 0.1); // this is the primary color, split into rgb
$tabs-text-color: #39acdd;
$tabs-underline-color: #39acdd;
$input-focus-color: #39acdd;

// Materialize
@import "../../../node_modules/materialize-css/sass/materialize.scss";
@import "../../../node_modules/materialize-css/extras/noUiSlider/nouislider.css";

// Datepicker
@import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

// Heatmap
@import "heatmap";

@import "variables.module.scss";
@import "trig.scss";
@import "design.scss";
@import "navbar.scss";
@import "highcharts.scss";
@import "timeline-card";
@import "detailed-view";
@import "skating-dashboard";
@import "soccer/soccer-detailed-view";
@import "soccer/soccer-timeline-view";
@import "soccer/soccer-dashboard";
@import "summary-table-multiple";
@import "stats-card";
@import "stats-view";
@import "questionnaire-dashboard";
@import "steps-dashboard";
@import "snapshots";
@import "data";
@import "network";
@import "animations";
@import "transitions";
@import "notifications";
@import "csv-dashboard.scss";
@import "premium";
@import "table-interactive.scss";
@import "mobile-table.scss";
@import "ribbon";
@import "inputs";
@import "pagination";
@import "coach-in-control";
@import "markdown";

body {
  -webkit-font-smoothing: antialiased;
  background-color: $footer-color;
}

// flash notices
.success {
  background-color: $success-color !important;
}
.success-text {
  color: $success-color !important;
}
.warning {
  background-color: $error-color !important;
}
.warning-text {
  color: $error-color !important;
}
.flash-notice {
  position: absolute;
  left: 30%;
  top: 40px;
}

// Some more room for form buttons
form {
  button.btn {
    margin-top: 2rem;
  }
}

// Footer styling
.footer {
  color: $secondary-color;
  width: 100%;
  margin-top: auto; // set the footer at the bottom of the page
  min-height: $footer-height * 1px;
  background-color: $footer-color;
  .container {
    margin-top: $footer-margin * 1px;
    margin-bottom: $footer-margin * 1px;
    .row {
      margin-bottom: 0;
    }
  }
  strong {
    font-weight: 700;
  }
  .compatible-with-strava {
    background-image: url("images/compatible_with_strava.svg");
    background-repeat: no-repeat;
    height: 45px;
    background-position: center;
  }
}
.footer .item {
  color: $muted-color;
  font-size: $m;

  &:hover, &:focus {
    color: $secondary-color;
  }
}

.muted-header {
  color: $muted-header-color;
}
//margin-top: 5rem;

.timeline-entry {
  background-color: $background-color;
  margin-bottom: 30px;
  margin-top: 30px;
  border: 1px solid rgba(150, 165, 178, 0.5);
  z-index: 11;
}

.timeline-subtitle {
  margin-bottom: 30px;
}

.responsive-height-img {
  height: 100% !important;
  float: right;
}

.nav-wrapper ul {
  height: 100%;
}

.menu-profile-name {
  margin-right: 10px;
}

nav .responsive-img {
  padding: 5px;
}

.responsive-img {
  min-width: 40px;
  min-height: 40px;
}

.nav-tabs {
  line-height: 36px;
  background-color: #ffffff;
  box-shadow: none;
  height: auto;
  padding: 0 !important;
  margin-top: 20px !important;
}

.nav-tabs li a {
  color: #e24a00 !important;
}

.square-card {
  position: relative;
  width: 100%;
  background-color: #fff;
  margin-bottom: 1.5rem;
}

.card-vertical-center {
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
}

.margin-right {
  margin-right: 0.75rem;
}

.margin-left {
  margin-left: 0.75rem;
}

.margin-top {
  margin-top: 0.75rem;
}

.margin-bottom {
  margin-bottom: 0.75rem;
}

.card {
  .card-content {
    .margin-top {
      margin-top: 1rem;
    }
    .margin-bottom {
      margin-bottom: 1rem;
    }
  }
}

.margin-top-large {
  margin-top: 4rem;
}

.chart-wrapper {
  position: relative;
}

.chart-controls {
  position: relative;
  padding: 0 0 1rem 1rem;
  z-index: 100;
  background: #fff;
}

.chart-controls > .active {
  border: 1px solid $primary-color;
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
}

.chart-controls > .inactive {
  border: 1px solid $inactive-color;
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
}


.nav-tabs li {
  background-color: rgba(246,178,181,0.2);
  position: relative;
  height: 100%;
  border-bottom: 1px solid #f6b2b5;
}

.bottom-line {
  line-height: 0 !important;
  left: 0;
}

.active-link {
  height: 5px;
  width: calc(100%);
  bottom: 0;
  border-radius: 2.5px;
  position: absolute;
  background-color: #f6b2b5;
  overflow: hidden;
}

.dropdown-content li > span {
  color: black;
}

.input-field > .select-wrapper > .select-dropdown {
  color: black;
}

.select-wrapper {
  margin-bottom: 23px
}

.select-wrapper + label {
  color: #39acdd;
}

.emphasized {
  font-style: italic
}

.pointer {
  cursor: pointer;
}

.grayed-out {
  filter: grayscale(100%);

  .pointer {
    cursor: default;
  }
  .pointer:hover, .pointer:hover p, .pointer:hover .text-primary-color {
    color: $primary-color !important;
  }
}

.grayed-out.button {
  cursor: not-allowed;
}

.pointer:hover, .pointer:hover p, .pointer:hover .text-primary-color {
  color: $secondary-color !important;
}

.pointer-grow {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.pointer-grow:hover {
  transform: scale(1.15);
}

.row.valign-wrapper.flex-start {
  align-items: flex-start;
}

@media only screen and (max-width: 600px) {
  .row.valign-wrapper { display: inherit; }
}

@media only screen and (max-width: 900px) {
  .row.profile-row.valign-wrapper {
    display: inherit;
  }
}

.collection {
  overflow: visible;
  border: none;
}

.collection .collection-item {
  margin: 0 0 20px 0;
}

.no-padding {
  padding: 0;
}

.select-dropdown li img {
  position: absolute;
  right: 0;
  margin-right: 5px !important;
}

.select-dropdown li img+span {
  margin-right: 50px;
}

ul.select-dropdown {
  min-width: 200px !important;
  max-height: 200px !important;
}

.long-select {
  ul.select-dropdown {
    max-height: none !important;
  }
}

.collection-item.row {
  padding-left: 0;
  padding-right: 0;
}

ul.collection {
  box-sizing: border-box;
}

.row.collection.valign-wrapper {
  display: inherit;
  padding: 0;
  margin: 0;
}

.collection-item.row {
  width: 100%;
}

@media only screen and (max-width: 600px)  {
  .left-align-on-small {
    text-align: left !important;
  }
}

.toggle {
  .switch {
    position: relative;
    display: flex;
    outline: none;
    min-height: 24px;
    line-height: 24px;
    cursor: pointer;
  }

  .switch .slider {
    margin-right: 20px;
    height: 24px;
    width: 50px;
    min-width: 50px;
  }

  .switch>span {
    display: inline-block;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
  }

  .switch input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .slider {
    display: inline-block;
    cursor: pointer;
    height: auto;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: $primary-color;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary-color;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);

  }
}

.no-margin {
  margin: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  margin-top: 10px;
}

.selected-version {
  color: #4db6ac
}

.pointer-non-hoverable {
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.version-item:hover .not-selected-version {
  opacity: 1;
}

.not-selected-version {
  color: #ddd;
  opacity: 0;
}

.welcome-left-menu {
  max-width: 350px;
}

@media only screen and (min-width: 1332px) {
  .welcome-left-menu + div, .welcome-left-menu + div + div, .welcome-left-menu + div + div + div {
    width: calc(100% - 350px) !important;
  }
}

@media only screen and (max-width: 800px) {
  .welcome-left-menu {
    width: 100% !important;
    max-width: 100%;
  }
  .welcome-left-menu + div, .welcome-left-menu + div + div, .welcome-left-menu + div + div + div {
    width: 100% !important;
  }
}

.welcome-container {
  position: relative;
  padding: 20px $large-container-padding 0 $large-container-padding;
  margin-top: -$b;
  margin-bottom: -20px;
  background-clip: content-box;
  .row {
    margin-bottom: 0;
    &.profile-info-row {
      margin-bottom: 20px;
    }
  }
}

.welcome-content {
  background-clip: padding-box;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;

  .welcome-container-button {
    margin-top: 0.75rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 900px) {
  .welcome-content {
    &>a>div>div:last-child, &>a>div:last-child, &>.profile-info-row {
      display: none;
    }
    .row.profile-header {
      margin-bottom: 0;
    }
    .welcome-back {
      margin-bottom: 0.75rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .welcome-content {
    .welcome-container-button {
      margin-top: 0;
    }
    .button-row {
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .col.s6.m12 {
        width: 50% !important;
        float: none;
        display: inline-block;
        &>.button {
          display: inline-block;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.padding-top {
  padding-top: 0.75rem;
}

.padding-bottom {
  padding-bottom: 0.75rem;
}

.label {
  display: block;
  font-size: 0.75rem;
  padding-bottom: 5px;
  color: $primary-color;
}

.value {
  display: block;
  font-size: 1rem;
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__close-icon, button.react-datepicker__navigation {
  &:focus {
    background-color: transparent;
  }
}

.tab {
  background-color: $background-color;
  padding: 20px;
}

.tab {
  .text {
    height: 45%;
  }
}

.tabs .tab.disabled a, .tabs .tab.disabled a:hover {
  &:focus {
    background-color: transparent;
  }
}

.tabs .tab a {
  padding: 0 4px;
  span {
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2rem;
  }
}

@media only screen and (max-width: 992px) {
  .tabs .tab a {
    padding: 0 2px;
  }
}

.tall-lines {
  line-height: 1.5;
}

.tab-active {
  background-color: $primary-color;
  color: #fff;
}

.text-responsive {
  font-size: 0.85rem;

}

.profile-row {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fff;
}

.field-postfix {
  position: absolute;
  right: 0;
  padding-right: 0.75rem;
  top: 18px;
}
.connections-container {
  margin-top: 20px;
}

.container {
  width: 80%;
  margin-top: 50px;
}

.collapsable-members-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.collapsable-members {
  position: absolute;
  background: #fff;
  z-index: 11;
  bottom: 100%;
  left: -75px;
  min-width: 200px;
}

.collapsable-members .collection {
  margin-top: 0;
}

.collapsable-members .collection {
  line-height: 0.75rem !important;
}

.collection-item {
  line-height: 2.5rem !important;
}

.questionnaire-view {
  .collection-item {
    line-height: 1.5rem !important;
  }
}


.collapsable-members .collection .collection-item:hover div {
  color: $secondary-color !important;
}

.hoverable-profile-wrapper {
  position: relative;
  display: inline;
  vertical-align: middle;
  &>span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
  }
}

.hoverable-profile {
  position: absolute;
  left: -75px;
  bottom: 100%;
  border: 1px solid #e0e0e0;
  box-shadow: 5px 5px 10px #e0e0e0;
  min-width: 200px;
  z-index: 11;
}

.hoverable-profile-link {
  display: inline-block;
}

.hoverable-profile .collection .collection-item {
  line-height: 1rem;
  box-sizing: border-box;
}

.hoverable-profile .collection .collection-item:hover div {
  color: $secondary-color !important;
}

.hidden {
  display: none;
}



.group-container, .profile-container, .general-container {
  background: #fff;
  margin-bottom: 0.75rem;
}

.general-content {
  padding: $container-padding $large-container-padding;
}

@media only screen and (max-width: 600px) {
  .general-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.custom-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000bb;
  z-index: 10000;
}

#confirmation-dialog {
  z-index: 10001;
}

.chip {
  color: $primary-color !important;
  background-color: white !important;
  border: 1px solid $primary-color;
  line-height: 30px;
  margin: 0.3rem 0.75rem 0.3rem 0;
  &:focus {
    color: white !important;
    background-color: $primary-color !important;
  }
  .margin-none {
    margin: 0;
  }
  .margin-side {
    margin: 0rem 0.75rem 0rem 0;
  }
}



.modal-header {
  content: "";
  height: 40px;
  margin-bottom: 20px;
}

.primary-border-bottom {
  border-bottom: 1px solid $primary-color !important;
}

.custom-modal {
  height: 80%;
  overflow-y: auto;
  background: #F8F8F8;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  z-index: 12;
  margin-top: 0;
  min-width: 304px; // 95% of 320px
  &#my-modal {
    background-color: $dashboard-background-color;
  }
  &:focus {
    outline: none;
  }
}

#cropper-container {
  svg {
    color: $primary-color;
  }
}

.custom-modal-inner {
  position: relative;
  width: calc(100% - 80px);
  height: calc(100% - 40px);
  margin: 0 40px 40px;
  padding-top: 60px;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .custom-modal {
    padding: 0 5px 5px;
    width: 95%;
    height: 97%;
    left: 2.5%;
    top: 1.5%;
    transform: none;
  }
  .custom-modal-inner {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 5px);
    margin: 0 5px 5px;
    padding-top: 60px;
  }

}

.white-label {
  color: #fff !important;
}

#search {
  border-color: $primary-color;
}

.custom-modal .close-button-wrapper {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
}

.custom-modal .close-button-container {
  border-radius: 100%;
  box-sizing: border-box;
  overflow: hidden;
  width: 30px;
  height: 30px;
  position: relative;
  line-height: 0;
}

.custom-modal .close-button {
  top: -50%;
  right: -50%;
  position: absolute;
}

.label-primary {
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  border: 1px solid #3385ff;
}

.no-breaks {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.picture-wrapper {
  position: relative;
}
.edit-button-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.btn-follow {
  background-color: #fff;
  padding: 0.25rem;
  border: 1px solid #33a0ff;
  border-radius: 0.25rem;
  width: 100%;
}

.btn-follow:focus {
  background-color: #fff;
}

.btn-follow:hover {
  background-color: #b3dbff;
}

.search-results-wrapper {
  position: relative;
  width: 100%;
  top: -20px;
  left: 0;
}

.search-results-container {
  position: absolute;
  z-index: 11;
  width: 100%;
}

.sport-icon-wrapper {
  width: 70px;
  height: 70px;
  line-height: 70px;
  background-color: $primary-color;
}

.sport-icon {
  font-size: 25px;
  color: $background-color;
}

.z-top {
  position: relative;
  z-index: 10;
  &.nav-bar {
    z-index: 10000; // Needs to be higher than high-z or the SDV logo is covered by the menu on small screens
  }
}

.z-middle {
  position: relative;
  z-index: 5;
}

.z-bottom {
  position: relative;
  z-index: 1;
}

.data-buttons-wrapper {
  margin-left: auto;
}

.data-button {
  width: 200px;
  margin-right: 1rem;
  height: 40px;
  margin-bottom: 8px;
  display: block;
  text-align: center;
  line-height: 1.15;
  &.wider-data-button {
    width: 310px;
  }
  &.smart-height-data-button {
    height: auto;
    min-height: 40px;
  }
}

.button {
  text-align: center;
  min-height: 40px;
  margin-bottom: 5px;
  display: block;
  line-height: 1.15;
}

.checkout-container {
  position: relative;
  min-height: 100%;
  padding-bottom: 60px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.subscription-button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}

.max-width-250 {
  max-width: 250px;
}

.button-row {
  .button {
    margin-bottom: 0;
  }
}

.data-button.data-detail-page {
  display: inline-block;
}

.data-card-icon {
  margin-left: 0 !important;
}

.login {
  max-width: 370px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.login-wrapper {
  margin-top: -50px;
  padding-top: 100px;
  padding-bottom: 30px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .login-wrapper {
    padding-top: 10%;
  }
  .login {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 450px) {
  .login {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.login-logo {
  width: 100%;
}

.login-title {
  text-align: center;
}

.login-content {
  ul {
    margin-bottom: 0;
  }
  li {
    margin-top: 0.5rem;
  }
}

[type="checkbox"] + span:not(.lever)::before {
  border: 2px solid $primary-color;
}

input[type="text"], input[type="password"], input[type="tel"], input[type="email"], input[type="number"], .chips {
  border-bottom: 1px solid $primary-color !important;
}


.dropdown-content {
  min-height: 160px;
}

.edit-profile {
  background-color: #fff;
  padding: $container-padding $large-container-padding;
}

@media only screen and (min-width: 993px) {
  .edit-profile-form {
    .form-field:nth-child(2n + 2):nth-child(-2n + 6) {
      width: 45%;
      display: inline-block;
      margin-right: 5%;
    }
    .form-field:nth-child(2n + 3):nth-child(-2n + 7) {
      width: 50%;
      display: inline-block;
    }
  }

  .profile-questionnaire-form {
    .form-field:nth-child(2n + 2) {
      width: 50%;
    }
    .form-field:nth-child(2n + 3) {
      width: 50%;
    }
  }
}

.select-multiple-icon {
  display: flex;
  flex-wrap: wrap;
}

// Fix annoying white bar at bottom of page
.app > .wrapper > .container > .row:last-child {
  margin-bottom: 0;
  padding-bottom: $footer-height * 1px;
}

// Fix text not vertically centered in add/create snapshot buttons
i.left {
  margin-top: -4px;
  margin-bottom: -4px;
}

.flash-messages-container {
  position: fixed;
  margin-top: 50px;
  width: 100%;
  z-index: 10002;
}

.__react_component_tooltip.higher-z {
  z-index: 10001;
}

.__react_component_tooltip {
  &>span.multi-line {
    text-align: start
  }
}

.message {
  display: block;
  top: 0;
  width: 70%;
  &:last-child {
    margin-bottom: 0;
  }
}

.confirmation-dialog {
  position: fixed;
  width: fit-content;
  height: fit-content;
  background-color: $background-color;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
}

.confirmation-dialog-buttons-wrapper {
  display: flex;
  justify-content: center;
}

.confirmation-dialog-buttons-wrapper button {
  margin: 1rem;
}

.change-role {
  display: inline-block;
  margin-left: 0.75rem;
}

@media only screen and (max-width: 992px) {
  .member-list {
    display: flex;
    align-items: center;
  }
}

.available-member {
  max-width: 200px;
  width: 200px;
  margin-right: 10px !important;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: inline-block;
  margin-top: 0.75rem;

  img {
    width: 30px;
    margin-right: 5px;
  }

  &.single-line {
    &>.valign-wrapper>.text-medium.text-m {
      width: calc(100% - 45px);
    }
  }
}

.responsive-table {
  .available-member {
    max-width: 300px;
    margin-right: auto !important;
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .responsive-table {
    .available-member {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      .valign-wrapper {
        position: relative;
        &>img {
          position: absolute;
          left: -10px;
        }
        &>div {
          margin-left: 40px;
        }
      }
    }
  }
}

.switch label input[type=checkbox]:checked + .lever {
  background: $primary-color;
}

.switch label input[type=checkbox]:checked + .lever:after {
  background: $secondary-color;
}

.dashboard-card {
  background-color: $background-color;
  height: 0;
  padding-top: 100%;
  position: relative;
  margin-bottom: 1.5rem;
  display: block;
}

.dashboard-card-title {
  position: absolute;
  top: 1rem;
  width: 100%;
}

.dashboard-card-icon-wrapper {
  pointer-events: none;
  position: absolute;
  height: 100%;
  bottom: 0;
  width: 100%;
  left: 0;
}

.dashboard-card-icon {
  width: 100%;
  font-size: 100px;
}

.dashboard-card-steps {
  a, .text-primary-color {
    color: $orange-color;
  }
}

.dashboard-card-enabled {
  @extend .dashboard-card;
  @extend .pointer;
}

.dashboard-card-wrapper {
  @extend .grow;
}

@media only screen and (max-width: 600px) {
  .grow, .dashboard-card-wrapper {
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}

.margin-card-bottom {
  margin-bottom: 20px;
}

.snapshot-buttons-wrapper button:first-child {
  margin-bottom: 5px !important;
}

.snapshot-buttons-wrapper button:nth-child(n+2) {
  margin-top: 5px !important;
}

.snapshot-sharing-rule, .data-dropzone {
  background: $background-color;
  padding: 20px 20px 0 !important;
}

.snapshot-sharing-rule img {
  height: 60px;
  margin-right: 20px;
}

.snapshot-sharing-rule svg {
  margin-left: auto;
  color: $accent-color;
}

.inline-block {
  display: inline-block;
}

@mixin side-button {
  color: #fff !important;
  height: 30px;
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  border: solid 1px rgba(255, 255, 255, 0);
  box-shadow: -2px 0 5px 0 rgba(0, 0, 0, 0.3);
  width: 100px;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  border-radius: 3px 3px 0 0;
}


.tutorial-button {
  @include side-button;
  top: 0;
  background: $orange-color !important;
}

.feedback-button {
  @include side-button;
  top: 50%;
  background: rgba(0, 0, 0, 0.7) !important;
}

.feedback-panel {
  width: 200px;
  -webkit-transform: rotate(-90deg);
  font-weight: 600;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  text-align: center;
  font-size: 17px;
  position: fixed;
  right: -87px;
  top: 50%;
  z-index: 999;
  transition: all .1s ease-in-out;
  border-radius: 3px 3px 0 0;
}

.data-overview-card {
  padding: $container-padding $large-container-padding;
}

.responsive-table {
  overflow-x: auto;
}

.high-z {
  z-index: 9999;
}

.react-datepicker-popper {
  @extend .high-z;
}

// By default, the popper displays a little bit under the text input where you click.
// This is around 11px and 51px. We override the existing translate style to force the
// system to use translate3d for this translation, which in Safari causes the z-index
// to be interpreted properly. Popper should automatically detect when it can use
// translate3d, but for some reason it doesn't.
.safari-proof-popper {
  transform: translate3d(11px, 51px, 0) !important;
}

.fixed-width-tooltip {
  max-width: 300px;
  font-weight: normal;
  line-height: normal;
  white-space: normal;
  &.wider-tooltip {
    width: 500px;
    max-width: 95%;
  }
}

.material-icons {
  vertical-align: middle;
}

#new-connection-search-tooltip {
  max-width: 95%;
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .fixed-width-tooltip {
    width: 500px;
    left: 2.5% !important;
    max-width: 95%;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.information-icon {
  margin-left: 0.4rem;
}

.margin-right-on-small {
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .margin-right-on-small {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 992px) {
  .wrapper > .container {
    width: 95%;
    max-width: 95%;
  }
  .container > .container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper > .container {
    width: 100%;
    max-width: 100%;

    & > .row > .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .contains-tabs, .padding-on-small {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .footer > .container {
    width: 100%;
    max-width: 100%;
  }
  .timeline-card, .edit-profile, .general-content, .data-overview-card, .profile-container {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

// Fix colors of sliders and radios in skating dashboard
.noUi-tooltip, .noUi-connect, .noUi-handle {
  background-color: $primary-color !important;
}
.no-noui-tooltips {
  .noUi-tooltip {
    display: none;
  }
}
.noUi-target.noUi-horizontal {
  margin-left: 5px;
  margin-right: 5px;
}
[type="radio"]:checked + span:after {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
[type="radio"]:not(:checked) + span:before, [type="radio"]:not(:checked) + span:after {
  border-color: $primary-color !important;
}

body {
  background-color: $main-background-color;
  background-image: url("images/bg-main.png");
  &.logout {
    background: url("images/bg-logout.jpg") no-repeat center center fixed;
    background-size: cover;
  }
}

.sharing-rule-button {
  margin: 5px;
  color: #bbb;
}

.add-to-home-banner {
  z-index: 10000;
  border-top: 1px solid $border-color;
  .add-to-home-icon {
    padding: 0 !important;
    margin-left: 7px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.graph-outline {
  background-color: $background-color;
  border-color: #39acdd;
  border-radius: 10px;
  border-width: 3px;
  border-style: solid;
  padding: 16px;
  border-top-width: 30px;
  position: relative;
}

.graph-title {
  position: absolute;
  top: -26px;
}

table.hovering-stats-table {
  @extend .z-depth-2;
}

.chart-reset-zoom-button {
  border: none;
}

.profile-progress-bar {
  border: 2px solid $primary-color;
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.profile-progress-bar-fill {
  display: inline-block;
  position: relative;
  top: -10px;
  height: 6px;
  border-radius: 6px;
  background-color: $primary-color;
}

.profile-progress-bar-missing-items {
  margin-right: 5px;
}

.first-connection-modal {
  height: 200px;
}

.emoji-icon {
  font-size: 50px;
}

.emoji-icon-large {
  font-size: 100px;
}

.bouncing-animation {
  animation-name: BOUNCING;
  animation-direction: alternate;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}

.jupyter-execution-count {
  padding: 0.5em !important;
  margin-top: 0.5em !important;
}

.atomic-table {
  background: $background-color;

  @media only screen and (min-width: 992px) {
    max-height: 250px;
    height: 250px;
    overflow-y: auto;
  }
}

.chartjs-padding-right {
  padding-right: 2.7rem;
}

.chartjs-summary {
  position: absolute;
  right: 1.2rem;
  bottom: 2.5rem;
  font-size: 0.85rem;
  & > .col { // so the items in the summary don't take up more width than their text
    float: none !important;
  }
}

@media only screen and (min-width: 601px) {
  .chartjs-padding-left {
    padding-left: 4.5rem;
  }
}

@media only screen and (min-width: 993px) {
  .bring-row-down > .row {
    margin-top: 1rem;
  }
}

.smoothness-label {
  position: absolute;
}

.circle-marker {
  display: inline-block;
  background-color: white;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.dropdown {
  margin-top: 24px;
  margin-left: -90px;
  position: relative;
  background-color: white;
  list-style: none;
  z-index: 999;
  cursor: pointer;
  width: max-content;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-item-span {
  margin: 8px;
}

.combined-icon-wrapper {
  position: relative;

  div:not(:first-child) {
    position: absolute;
    left: 0;
    top: 0;
  }

  div.super-premium-icon-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    svg {
      font-size: 13px;
    }
    &:first-child {
      left: 7px;
    }
    &:nth-child(2) {
      top: 7px;
    }
  }
}

.noUi-tooltip-hr-custom span {
  color: #0c0c0c !important;
}

@media only screen and (min-width: 993px) {
  .table-data-sharing-scroll tbody {
    display: block;
    max-height: 600px;
    overflow-y: scroll;
  }

  .table-data-sharing-scroll thead, .table-data-sharing-scroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .table-data-sharing-scroll thead {
    width: calc( 100% - 1.1em );
  }
  .data-sharing-c1 {
    width: 25%;
    word-break: break-all;
  }
  .data-sharing-c2 {
    width: 15%;
    word-break: break-all;
  }
  .data-sharing-c3 {
    width: 25%;
    word-break: break-all;
  }
  .data-sharing-c4 {
    width: 10%;
    word-break: break-all;
  }
  .data-sharing-c5 {
    width: 10%;
    word-break: break-all;
  }
  .data-sharing-c6 {
    width: 5%;
    word-break: break-all;
  }

  // same table, but for the onboarding modal which has fewer columns
  .onboarding-data-sharing-c1 {
    width: 30%;
    word-break: break-all;
  }

  .onboarding-data-sharing-c2 {
    width: 50%;
    word-break: break-all;
  }

  .onboarding-data-sharing-c3 {
    width: 20%;
    word-break: break-all;
  }
}

// react-modal doesn't allow compositing classNames, so in order to add a class, a definition of the defaults is necessary
.react-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

// Mostly the same as default, minus insets (so the modal wraps the content), added max-w/h, and a shadow effect
.modal-centered-content {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 20px;
  background: rgb(255, 255, 255) none repeat scroll 0 0;
  max-width: 95%;
  max-height: 95%;
}

.scale-image {
  max-width: 100%;
  max-height: 100%;
}

.entry:hover {
  background-color: $accent-color-light !important;
  color: black !important;
}

.entry-selected {
  background-color: $accent-color !important;
  color: $background-color;
}

.entry-selected:hover {
  background-color: $accent-color !important;
  color: $background-color !important;
}

.pinned.table-header th {
  position: sticky !important;
  background-color: $background-color;
  z-index: 1;
  top: 0;
}

.pinned.table-header {
  position: inherit !important;
}

.full-height {
  height: 100%;
}

.valign-text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.overflow-text-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result-image {
  max-width: 42px !important;
}

.search-result-text {
  float: left;
  padding-left: 10px;
}

.search-result-margin {
  margin-top: 5px;
  margin-bottom: 5px;
}

.search-result-image-container {
  max-height: 42px;
  float: left
}

.search-results {
  border: 1px solid #e0e0e0;
  box-shadow: 5px 5px 10px #e0e0e0;
}

.collection-item.search-result-item {
  margin-bottom: 0;
  padding: 10px 10px;
}

.rings-container {
  @media (min-width: 992px), (max-width: 600px) {
    position: relative;
    top: 50%;
    &.translated-up {
      transform: translateY(-50%);
    }
  }
}

.info-notice-container {
  display: flex;
  align-items: center;
  div {
    padding: 0 20px;
  }
}

.redeem-container {
  margin-top: 37px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);

  .redeem-voucher-title {
    padding: 0 20px;
  }

  .redeem-voucher-form {
    padding: 20px 20px 0 20px;

    .voucher-field > .row {
      margin-bottom: 0;
    }

    > .row {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .col > .input-field {
        margin: 0;
      }
    }
  }
}

.no-resize-handle {
  resize: none
}

.display-linebreak {
  white-space: pre-line;
}

.fixed-textarea-height {
  height: 300px;
  padding: 16px;
  border-color: $primary-color;
  outline-color: $primary-color;
}

// react-select
.basic-multi-select {
  z-index: 1000;
  transform: translate3d(0, 0, 0) !important; // safari-proof-z-index
  input, input:not(.browser-default):focus:not([readonly]) {
    border: none !important; // override materialize styling
    box-shadow: none;
  }
}

.multi-select-group {
  cursor: pointer;
  &:hover {
    color: black;
    text-shadow: 0 0 5px grey;
  }
}
