@import "variables.module.scss";

.input-field > .header-dropdown {
  max-width: 150px;
  color: $muted-color;
  li {
    font-weight: normal;
  }
}

.category-main {
  display: flex;

  .category-icon {
    order: 1;
    padding: 10px;
  }

  .category-content {
    order: 2;
    flex-basis: 70%;
    text-align: center;
    color: $black-color;
    @extend .text-l
  }
}

.category-leaf {
  color: $muted-color;
}

.tr-with-category-main {
  // Hide the large table headers on medium and below screen sizes
  // We show a different table for mobile - #mobile-table
  @media only screen and (max-width: 992px) {
    display: none !important; // need !important because otherwise this is overridden by native display: block from the materialize framework
  }
}
