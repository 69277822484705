.summary-table-multiple {
  tr {
    background-color: white;

    :first-child {
      padding-left: 20px;
      @extend .text-muted, .text-heavy;
    }
    :last-child {
      padding-right: 26px;
    }
    td, th {
      padding-top: 10px;
      padding-bottom: 10px;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
}