@import "../variables.module";

.soccer-detailed-view {
  .heatmap-team-container {
    @extend .z-depth-1;
    background-color: $secondary-color;

    .heatmap-team-wrapper {
      margin-right: 5px;
      margin-left: 5px;

      > .row {
        margin-bottom: 0;
      }

      > .row:first-child {
        border-bottom: 2px solid $black-color;
      }
    }
  }

  h6.heatmap-team-title {
    text-align: center;
    padding: 10px 0;
    color: white;
    font-size: x-large;

  }

  .heatmap-team-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
