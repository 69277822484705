// Colors
$primary-color: #39acdd;
$secondary-color: #011258;
$button-primary-color: #011258;
$accent-color: #d34316;
$accent-color-transparent: rgba(211, 66, 22, 0.1);
$orange-color: #FFA239;
$background-color: #FFF;
$dashboard-background-color: #ecf0f6;
$inactive-color: #BEBEBE;
$black-color: #3c3c3c;
$black-transparent: rgba(0, 0, 0, 0.32);
$muted-color: #6c757d;
$muted-header-color: rgb(20, 23, 26);
$border-color: rgb(230, 236, 240);
$label-color: #616161;

$primary-color-light: #D4F2FF;
$secondary-color-ligth: #cdd6fe;
$accent-color-light: #fff1eb;
$accent-color-dark: #ce3000;

$green-dark: #006633;
$green: #00CC66;
$green-light: #99FFCC;

$gray-chartjs: #D4D8DD;

// Blue background color
//$main-background-color: #011258;
// Grey background color according to Frank
$main-background-color: #F3F7FA;

// Font sizes
$huge: 61px;
$xxxl: 50px;
$xxl: 32px;
$xl: 24px;
$l: 18px;
$m: 14px;
$s: 12px;

// Margins and padding
$container-padding: 20px;
$large-container-padding: 31.250px;

// Footer size
$footer-margin: 10;
$footer-height: 23 + $footer-margin * 2;
$footer-color: white;

// Z-Index
$top-most-z-index: 99999;

:export {
  primaryColor: $primary-color;
  primaryColorLight: $primary-color-light;
  secondaryColor: $secondary-color;
  secondaryColorLight: $secondary-color-ligth;
  accentColor: $accent-color;
  accentColorLight: $accent-color-light;
  accentColorDark: $accent-color-dark;
  green: $green;
  greenDark: $green-dark;
  greenLight: $green-light;
  blackTransparent: $black-transparent;
  grayChartjs: $gray-chartjs;
  labelColor: $label-color;
  mainBackgroundColor: $main-background-color;

  topMostZIndex: $top-most-z-index;
}