
.react-calendar-heatmap .color-scale-1 {
  fill: #ADD5F1;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #80A8CE;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #477097;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #234B73;
}

.color-empty {
  background-color: #eee;
}
.color-scale-1 {
  background-color: #ADD5F1;
}
.color-scale-2 {
  background-color: #80A8CE;
}
.color-scale-3 {
  background-color: #477097;
}
.color-scale-4 {
  background-color: #234B73;
}

.legend {
  margin: 0.5em 0.5em;
  display: flex;
}

.legend-item {
  width: 1.40em;
  height: 1.40em;
  margin: 1px;
}
