@import "variables.module.scss";

.mobile-table, .mobile-table-headers {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;

  th, td {
    padding: 8px;
    text-align: left;
  }

  th {
    border: none;
  }

  td {
    text-align: center;
    color: $black-color;
    border: 1px solid #ddd;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    background-color: $dashboard-background-color;
    color: $muted-color;
  }
}

.mobile-table-wrapper {
  overflow-x: auto; /* Adds horizontal scrollbar */
  width: 100%; /* Sets width to 100% of its container */
}
