@import "variables.module";

$duration: 200ms;

@mixin small {
  opacity: 0;
  transition: $duration ease;
  max-height: 0;
  overflow: hidden;
}

@mixin big($size) {
  opacity: 1;
  transition: $duration ease;
  max-height: $size;
  overflow: hidden;
}

@mixin slide-transition($klassname, $size) {
  .#{$klassname}-enter, .#{$klassname}-appear {
    @include small;
  }

  .#{$klassname}-enter-active, .#{$klassname}-appear-active {
    @include big($size);
  }

  .#{$klassname}-exit {
    @include big($size);
  }

  .#{$klassname}-exit-active {
    @include small;
  }
}

// The max height can be larger than the actual height and it's fine.
@include slide-transition(snapshot-card, 600px);
@include slide-transition(group-announcement-row, 105px);

.collection.files {
  border-top: none;
  max-height: 211px;
  overflow-y: auto;

  .collection-item:last-child {
    border-top: 1px solid #e0e0e0;
  }

  @include slide-transition(item, 50px);
}
