@import "variables.module";

.highcharts-container {
  .highcharts-title {
    color: #333 !important;
    font-size: 18px !important;
    font-family: 'Raleway', arial, sans-serif !important;
    font-weight: 700 !important;
    line-height: normal !important;
    white-space: nowrap !important;
  }
}
