@import "variables.module";

.fixed-height-list {
  height: 125px;
}

@media (max-width: 601px) {
  /* add a bit of bottom margin for mobile vertical stacking */
  .mobile-bottom-margin {
    margin-bottom: 20px;
  }
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropzone,
.pending-uploads-list {
  height: 315px;
}

.data-table {
  tr {
    td:first-child {
      @extend .text-muted;
      font-weight: bold;
      text-align: right;
    }
    td {
      a {
        word-break: break-all;
      }
    }
  }
}

.text-muted {
  color: $muted-color !important;
}

.page-button {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  color: $primary-color;
}

.page-button:hover {
  cursor: pointer;
  color: $secondary-color;
}

.page-button-active {
  color: $secondary-color;
  &:hover {
    cursor: default;
  }
}

.data-button {
  .button-icon {
    margin-right: 5px;
  }
}

.data-header {
  margin-top: 2rem;
}

.questionnaire-header {
  margin-top: 2rem;
}

/*
// I think it looks better without the scroll bars?
.tag-field {
  overflow: scroll;
}
*/

.dropzone-margin {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.icon-primary-color {
  color: $primary-color !important;
}

.jupyter-output {
  overflow-x: scroll;
}

.jupyter-figure {
  width: 100%;
}

.jupyter-plaintext {
  white-space: pre-line;
}

.csv-table-container {
  overflow-x: auto;
}

.collection.files {
  border: 1px solid #e0e0e0;
  overflow: hidden;
  .collection-item {
    margin: 0;
    .secondary-content {
      color: $primary-color;
      padding: 10px;
      margin-top: -10px;
      margin-right: -10px;
      cursor: pointer;
    }
  }
}

.data-overview-table.with-selection {
  margin-top: 25px;
  position: relative;
}

.data-overview-table.delete-button {
  white-space: nowrap;
  margin-top: -25px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0;
}

.data-overview-table.edit-delete-section {
  display: flex;
  flex-direction: row-reverse;
  white-space: nowrap;
}

.checkonly {
  margin: 6px 0 0 0;
  display: inline-block;
  &.read-only {
    pointer-events: none;
    filter: grayscale(100%);
  }
}

.show-data-buttons {
  & > span {
    & > a {
      vertical-align: bottom;
    }
  }
}

@media only screen and (max-width: 992px) {
  .data-overview-table.with-selection {
    margin-top: 0;
    padding-top: 25px;
  }
}

.strava-link {
  color: #fc4c02;
}
